import { createApp } from 'vue'
import App from './App.vue'

// Import Axios
import axios from 'axios';

// Set Axios as a global variable
window.axios = axios;

createApp(App).mount('#app')
