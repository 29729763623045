<template>
    <footer class="footer"> © 2023 <a href="https://crisrendoque.com" target="_blank" class="link link--light">Cris Rendoque</a> | All rights reserved.</footer>
</template>


<script>
export default {
 name: 'AppFooter',
}
</script>
