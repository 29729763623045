<template>
    <aside class="doc__nav">
        <ul>
          <li @click="scrollToSection('get-started')" :class="{ 'selected': selectedLink === 'get-started' }">Get Started</li>
          <li @click="scrollToSection('api-endpoint')" :class="{ 'selected': selectedLink === 'api-endpoint' }">API Endpoints</li>
          <li @click="scrollToSection('basic-info')" :class="{ 'selected': selectedLink === 'basic-info' }">Basic Information</li>
          <li @click="scrollToSection('education')" :class="{ 'selected': selectedLink === 'education' }">Education</li>
          <li @click="scrollToSection('careers')" :class="{ 'selected': selectedLink === 'careers' }">Careers</li>
          <li @click="scrollToSection('certificates')" :class="{ 'selected': selectedLink === 'certificates' }">Certificates</li>
        </ul>
      </aside>
</template>


<script>
export default {
  name: 'LeftNav',
  data() {
    return {
      selectedLink: 'get-started',
    };
  },
  methods: {
    scrollToSection(sectionId) {
      // Scroll to the section
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }

      // Update the selectedLink data
      this.selectedLink = sectionId;
    },
  }
};
</script>

