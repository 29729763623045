<template>
    <section id="api-endpoint" class="section">
          <h3 class="section__title">API Endpoints</h3>
          <p>Use the following endpoints to access my profile.</p>

          <div class="code__block code__block--notabs">
              <pre class="code code--block">
                <code>
                  $ base-url: https://api.crisrendoque.com/
                </code>
              </pre>
          </div>
          
          <div class="table-responsive">
            <table id="customers">
            <tr>
              <th>Resources</th>
              <th>Endpoint</th>
              <th>Method</th>
            </tr>
            <tr>
              <td>Basic Information</td>
              <td>{base-url}/v1/</td>
              <td>GET</td>
            </tr>
            <tr>
              <td>Education</td>
              <td>{base-url}/v1/education</td>
              <td>GET</td>
            </tr>
            <tr>
              <td>Careers</td>
              <td>{base-url}/v1/career</td>
              <td>GET</td>
            </tr>
            <tr>
              <td>Certificates</td>
              <td>{base-url}/v1/certificates</td>
              <td>GET</td>
            </tr>
          </table>
          </div>
          
        </section>
</template>


<script>
export default {
 name: 'ApiEndpoints',
}
</script>
