<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <div id="get-started"></div> <!-- For navigation purposes-->
    <AppHeader />
    <div class="wrapper">
      <LeftNav/>
      <article class="doc__content">
        <GetStartedSection />
        <ApiEndpoints />
        <BasicInfoSection />
        <EducationSection />
        <CareerSection />
        <CertificatesSection />
      </article>
    </div>
    <AppFooter />
    <back-to-top text="Back to top"></back-to-top>
  </div>  
</template>

<script>
import AppHeader from './components/partials/Header.vue';
import AppFooter from './components/partials/Footer.vue';
import LeftNav from './components/partials/Nav.vue';
import GetStartedSection from './components/section/GetStarted.vue';
import ApiEndpoints from './components/section/Endpoints.vue';
import BasicInfoSection from './components/section/BasicInfo.vue';
import EducationSection from './components/section/Education.vue';
import CareerSection from './components/section/Career.vue';
import CertificatesSection from './components/section/Certificates.vue';
import BackToTop from 'vue-backtotop'

export default {
  name: 'App',
  components: {
    AppHeader,
    AppFooter,
    LeftNav,
    GetStartedSection,
    ApiEndpoints,
    BasicInfoSection,
    CareerSection,
    EducationSection,
    CertificatesSection,
    BackToTop
  }
}
</script>
