<template>
    <div class="doc__bg"></div>
    <nav class="header">
      <h1 class="logo">Cris <span class="logo__thin">Rendoque</span></h1>
      <ul class="menu">
        <div class="menu__item toggle"><span></span></div>
        <li class="menu__item"><a href="https://github.com/ledsummer" target="_blank" class="link link--dark"><i class="fa fa-github"></i> Github</a></li>
        <li class="menu__item"><a href="https://www.linkedin.com/in/ledsummer/" target="_blank" class="link link--dark"><i class="fa fa-linkedin"></i> LinkedInn</a></li>
      </ul>
    </nav>
    
</template>


<script>
export default {
 name: 'AppHeader',
}
</script>
