<template>
    <section id="get-started" class="section">
          <h3 class="section__title">Get Started</h3>
          <p>Hi there! Im <b>Cris Rendoque</b> a versatile professional skilled in Web Development from Philippines 🇵🇭. I'm currently working as a Business Solution Analyst in Saudi Arabia 🇸🇦.</p>
          <p>To learn more about me please check the following API endpoint on the next section.</p>

          <h3 class="section__title">Usage</h3>
          <p>To access my profile you can use <b>Axios Library</b> to make HTTP request from nodejs.</p>

            <div class="code__block code__block--notabs">
              <pre class="code code--block">
                <code>
                  const axios = require('axios');

                    let config = {
                        method: 'get',
                        maxBodyLength: Infinity,
                        url: 'https://api.crisrendoque.com/',
                        headers: { }
                    };

                    axios.request(config)
                    .then((response) => {
                        console.log(JSON.stringify(response.data));
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                </code>
              </pre>
            </div>

            <h4>Example Response</h4>
            <div class="code__block code__block--notabs">
              <pre class="code code--block">
                <code>
                  HTTP/1.1 200 OK
                  {
                      "title": "portfolio-api",
                      "version": "1.0",
                      "status": true,
                      "resource": "basic-info",
                      "message": "Basic Information",
                      "data": {..}
                  }
                </code>
              </pre>
            </div>

            <table id="customers">
            <tr>
              <th>Field</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>title</td>
              <td>API title</td>
            </tr>
            <tr>
              <td>version</td>
              <td>The current version of the API</td>
            </tr>
            <tr>
              <td>status</td>
              <td>Status of the request using boolean data type (true or false)</td>
            </tr>
            <tr>
              <td>resource</td>
              <td>Profile section. Basic information, education, career, certificates</td>
            </tr>
            <tr>
              <td>data</td>
              <td>Objects base on the resources selected</td>
            </tr>
          </table>

        </section>
</template>


<script>
export default {
 name: 'GetStartedSection',
}
</script>
