<template>
    <section id="certificates" class="section">
          <h3 class="section__title">Certificates</h3>

          <p>This object contains my Certificate Information.</p>

          <button class="request-button" @click="makeApiRequest">
            <span class="method">GET</span>
            <span class="endpoint">{base-url}/v1/certificates</span>
          </button>

          <!-- Loading progress bar -->
          <div class="loading-container" v-if="loading">
            <div class="loading-bar"></div>
            <div class="loading-text">Preparing data . . .</div>
          </div>
          <!-- Response container -->
          <div  v-if="!loading && apiResponse !== null">
            <p>Response:</p>
              <div class="json-response">
              <pre>{{ apiResponse  }}</pre>
            </div>
          </div>
          
        </section>
</template>


<script>
export default {
 name: 'CertificatesSection',
 data() {
    return {
      baseUrl: "https://api.crisrendoque.com", // my api
      apiResponse: null,
      loading: false,
      loadingPercentage: 0, // Loading percentage
    };
  },
  methods: {
    makeApiRequest() {
      this.loading = true;


      // Use axios to make the API request
      window.axios
        .get(`${this.baseUrl}/v1/certificates`)
        .then((response) => {

          setTimeout(() => {
            this.loading = false; // Hide loading progress bar
            this.apiResponse = JSON.stringify(response.data.data, null, 2); // Replace with your API response
          }, 900); // Simulated 2-second delay (adjust as needed)

        })
        .catch((error) => {
          this.loading = false;
          console.error(error);
          this.apiResponse = "Error occurred while making the API request.";
        });
    },
  },
}
</script>
